import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleTeaser from "../components/article-teaser"
import TagList from "../components/tag-list"
import Pagination from "../components/pagination"
import normalize from "../utils/tools"

const CategoryPage = ({ pageContext, data }) => {
  let description_processed = false
  if (pageContext.description) {
    description_processed = normalize(pageContext.description, data.allFileFile)
  }
  return (
    <Layout>
      <SEO title={pageContext.name}/>
      <section>
        {description_processed ? (
          <>
            <header className="major">
              <h2>{pageContext.name}</h2>
            </header>
            <section>
              <div className="description">
                {description_processed}
              </div>
            </section>
            <header className="major">
              <h3>Page { pageContext.currentPage} of { pageContext.numPages }</h3>
            </header>
          </>
        ) : (
          <header className="major">
            <h2>{pageContext.name}: page { pageContext.currentPage} of { pageContext.numPages }</h2>
          </header>
        )}

        <div className="posts">
          {data.allNodeArticle.edges.map(edge => {
              return <ArticleTeaser data={edge} key={edge.node.id}/>
            }
          )}
        </div>
        <div className="align-center">
          <Pagination currentPage={pageContext.currentPage} numberPages={pageContext.numPages} subPage={pageContext.alias + '/page'} />
        </div>
      </section>

      <header className="major">
        <h2>Tags & Categories</h2>
      </header>

      <div className="row">
        <div className="col-6 col-12-small">
          <TagList items={data.allTaxonomyTermTags.edges} name="Tags"/>
        </div>
        <div className="col-6 col-12-small">
          <TagList items={data.allTaxonomyTermCategory.edges} name="Categories"/>
        </div>
        <div className="col-6 col-12-small">
          <TagList items={data.allTaxonomyTermCountries.edges} name="Country Tags"/>
        </div>
      </div>
    </Layout>
  )
}

export default CategoryPage

export const CategoryPageQuery = graphql`
      query CategoryPageQuery ($limit: Int!, $skip: Int!, $tags_id: String, $category_id: String, $country_id: String) {
        allNodeArticle (
          limit: $limit, 
          skip: $skip, 
          sort: {order: DESC, fields: [sticky, created]}, 
          filter: { status: {eq: true}, 
          relationships: {
            field_tags: {elemMatch: {id: {eq: $tags_id}}},
            field_categories: {elemMatch: {id: {eq: $category_id}}},
            field_related_countries: {elemMatch: {id: {eq: $country_id}}}}
          }) {
          edges {
            node {
              id,
              title
              body {
                summary
                value
              }
              created
              path {
                alias
              }
              relationships {
                field_cover {
                  relationships {
                    field_media_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 480, height: 350, quality: 100, cropFocus: CENTER) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        allTaxonomyTermTags(sort: {order: ASC, fields: name}) {
            edges {
              node {
                id
                name
                path {
                  alias
                }
              }
            }
          }
        allTaxonomyTermCategory(sort: {order: ASC, fields: name}) {
          edges {
            node {
              id
              name
              path {
                alias
              }
            }
          }
        }
        allTaxonomyTermCountries(sort: {order: ASC, fields: name}) {
          edges {
            node {
              id
              name
              path {
                alias
              }
            }
          }
        }
        allFileFile {
          edges {
            node {
            uri {
              url
            }
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1024) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                }
              }
            }
          }
        }
      }
    `
